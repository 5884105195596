import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { HandleChange, CheckFieldValidity } from "../../utility/Form";
import CommonAddItemComp from "../common/Add";
import FormFooter from "../common/FormFooter";
import { navigate } from "@reach/router"
import { set, isEmpty, get } from "lodash";
import { setUser, isLoggedIn } from "../../utility/Auth"

import LogoImg from "../../images/logo-full.svg";

const AddFormControls = (props) => {
  const [isAuth, setIsAuth] = useState(isLoggedIn());
  useEffect(() => {
    if (isAuth) {
      navigate('/agents/list');
    }
  }, [isAuth]);

  set(props, 'additionalParams.isAuth', true)
  const [formError, setFromError] = useState({});
  const CheckFormFieldValidity = (event) => {
    const FieldError = CheckFieldValidity(event);
    setFromError({ ...formError, ...FieldError });
  }

  const HandleChangeFunc = (event) => {
    HandleChange(event);
    CheckFormFieldValidity(event)
  }

  const FormSubmitFunc = async (event) => {
    try {
      const Result = await props.HandleSubmitFunc(event, {
        ...props.additionalParams, enableValidation: true,
        isAuth: true, isSubmit: true
      })
      if (get(Result, 'formSubmit.hasError')) {
        setFromError(Result.formSubmit.error);
      } else {
        //if login successfull store the token and data
        //set user and token in localstorage
        if (!isEmpty(Result.submittedResult.data)) {
          setUser(Result.submittedResult.data);
          navigate(`/agents/list`);
        }
      }
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  return (
    <>
      <div className="loginForm">
        <div className="logo_img"><img src={LogoImg} alt={"Resiland"} title={"Resiland"} /></div>
        <Form noValidate onSubmit={(event) => FormSubmitFunc(event)}>
          <Row className="my-3">
            <Col className="mb-4">
              <div className="form-wrapper">
                <label>Email * </label>
                <input type="text" name="email" autoComplete={false} className={`form-control${formError?.email?.hasError ? ' pu-has-control-error' : ''}`}
                  onChange={(event) => HandleChangeFunc(event)}
                  required={true}
                  // Every \ should comes with \\ due to string to regexp conversion
                  pattern={"\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"}
                  placeholder="" />
                {
                  (formError?.email?.hasError && ((formError?.email?.type === 'required') ? (
                    <div className="pu-has-error">
                      Email is required!
                    </div>
                  ) : (
                    formError?.email?.type === 'pattern') ? (
                    <div className="pu-has-error">
                      Email is invalid!
                    </div>
                  ) : ""))
                }
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="mb-4">
              <div className="form-wrapper">
                <label>password * </label>
                <input type="password" name="password" autoComplete={false} className={`form-control${formError?.password?.hasError ? ' pu-has-control-error' : ''}`}
                  onChange={(event) => HandleChangeFunc(event)}
                  required={true}
                  placeholder="" />
                {
                  ((formError?.password?.hasError && (formError?.password?.type === 'required')) && <div className="pu-has-error">
                    Password is required!
                  </div>)
                }
              </div>
            </Col>
          </Row>
          <FormFooter parentPath={props.parentPath} actionType="login"></FormFooter>
        </Form>
      </div>
    </>
  )
}

const Login = (props) => {
  return <CommonAddItemComp PuFormControlsAdd={
    (HandleSubmitFunc) => {
      return (
        <>
          <AddFormControls HandleSubmitFunc={HandleSubmitFunc} {...props} />
        </>
      )
    }
  } {...props} SkipDefaultHeader={true} />
}

export default Login;