import * as React from "react"
import Login from "@components/auth/Login"
import Seo from "@components/seo"
import "../../styles/index.scss"
import "../../styles/custom.scss"

const AdditionalParams = {
  endPointName: "auth.login"
}

const LoginPage = (props) => (
  <>
    <Seo title="Login" />
    <div className="main-wrapper login_wrapper">
      <Login
        menu="Login"
        parentPath="auth"
        pageType="Login"
        additionalParams={AdditionalParams} />
    </div>
  </>
)

export default LoginPage;
